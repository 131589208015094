import React from 'react';

import { useMatchMedia } from '../../hooks/use-match-media';

import { ScTextCardItem, ScTextCardItemTextWrapper } from './styled';

export interface ITextCardItem {
	smallTitle: string;
	largeTitle: string;
	text: string;
}

interface IImageTextCard {
	item: ITextCardItem;
	breakpoint: number;
	background?: string;
	color?: string;
}

export const TextCard2 = ({ item, background, color, breakpoint }: IImageTextCard) => {
	const isCustomBreakpoint = useMatchMedia(`(min-width: ${breakpoint}px)`);

	return (
		<ScTextCardItem background={background}>
			<ScTextCardItemTextWrapper color={color}>
				<h6
					dangerouslySetInnerHTML={{
						__html: isCustomBreakpoint ? item.largeTitle : item.smallTitle,
					}}
				/>
				<p dangerouslySetInnerHTML={{ __html: item.text }} />
			</ScTextCardItemTextWrapper>
		</ScTextCardItem>
	);
};
