import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ScBenefitsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 25px;
	align-items: center;
	margin-top: 40px;
	@media (min-width: 768px) {
		gap: 50px;
		margin-top: 100px;
	}

	@media (min-width: 1220px) {
		margin-top: 120px;
		gap: 60px;
	}
	@media (min-width: 1440px) {
		gap: 60px;
	}

	@media (min-width: 1920px) {
		margin-top: 200px;
		gap: 100px;
	}
`;

export const TheCookiesWeSetWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 25px;
	align-items: center;
	margin-top: 40px;
	margin-bottom: 40px;
	@media (min-width: 768px) {
		gap: 50px;
		margin-top: 100px;
		margin-bottom: 40px;
	}

	@media (min-width: 1220px) {
		margin-top: 120px;
		gap: 60px;
	}
	@media (min-width: 1440px) {
		margin-bottom: 60px;
		gap: 60px;
	}

	@media (min-width: 1920px) {
		margin-top: 200px;
		margin-bottom: 70px;
		gap: 100px;
	}
`;

export const ScBenefitsTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
	max-width: 1130px;

	@media (min-width: 1220px) {
		gap: 60px;
	}
`;

export const ScBenefitsItemsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-bottom: 8px;
	width: 100%;

	@media (min-width: 1220px) {
		h6 {
			font: ${({ theme }) => theme.fonts.heading.cardMedium};
		}
	}

	@media (min-width: 1440px) {
		gap: 20px;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		margin-bottom: 20px;

		> div {
			> div {
				padding: 56px 28px 30px 28px;
			}
		}

		p,
		h6 {
			margin: 0;
		}

		h6 {
			font: ${({ theme }) => theme.fonts.heading.cardSmall};
		}
	}

	@media (min-width: 1920px) {
		h6 {
			font: ${({ theme }) => theme.fonts.heading.cardMedium};
		}
	}
`;
export const ScInfoButtons = styled(motion.div)`
	display: flex;
	flex-direction: column;
	align-items: center;
	align-items: center;
	gap: 8px;
	margin-top: 10px;
	@media (min-width: 1220px) {
		gap: 24px;
	}
`;

export const ScInfoSectionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 15px;
	margin: 50px auto;
	max-width: 875px;
	@media (min-width: 1220px) {
		gap: 42px;
		margin: 120px auto;
	}
	@media (min-width: 1440px) {
		max-width: 1000px;
	}
	@media (min-width: 1920px) {
		margin: 200px auto;
	}
`;

export const ScLastUpdateWrapper = styled.span`
	font: ${({ theme }) => theme.fonts.text.enormous};
	margin-bottom: 120px;
	display: block;
	@media (min-width: 360px) {
		font: ${({ theme }) => theme.fonts.text.small};
		margin-bottom: 50px;
	}
	@media (min-width: 768px) {
		font: ${({ theme }) => theme.fonts.text.medium};
		margin-bottom: 50px;
	}
	@media (min-width: 1220px) {
		font: ${({ theme }) => theme.fonts.text.xl};
		margin-bottom: 120px;
	}
	@media (min-width: 1440px) {
		font: ${({ theme }) => theme.fonts.text.enormous};
	}
	@media (min-width: 1920px) {
		font: ${({ theme }) => theme.fonts.text.xl};
		margin-bottom: 120px;
	}
`;
