import styled from 'styled-components';

interface IScTextCardItem {
	background?: string;
}

export const ScTextCardItem = styled.div<IScTextCardItem>`
	display: flex;
	flex-direction: column;
	border-radius: 10px;
	padding: 20px;
	box-sizing: border-box;
	background: ${({ theme, background }) => background || theme.colors.toasted};
	height: 100%;
	position: relative;
	min-height: 132px;

	@media (min-width: 768px) {
		padding: 70px 30px;
		border-radius: 30px;
	}
	@media (min-width: 1440px) {
		padding: 56px 28px 30px 28px;
		border-radius: 30px;
	}
`;

interface IScTextCardItemTextWrapper {
	color?: string;
}

export const ScTextCardItemTextWrapper = styled.div<IScTextCardItemTextWrapper>`
	display: flex;
	flex-direction: column;
	gap: 16px;
	align-items: center;

	h6,
	p {
		text-align: center;
		margin: 0;
	}

	h6 {
		color: ${({ theme, color }) => color || theme.colors.strongWhite};
		font: ${({ theme }) => theme.fonts.heading.xss};
	}

	p {
		color: ${({ theme, color }) => color || theme.colors.strongWhite};
		font: ${({ theme }) => theme.fonts.paragraph.small};
		margin: 0;
	}

	@media (min-width: 768px) {
		justify-content: center;
		gap: 32px;
		h6,
		p {
			text-align: center;
		}
		h6 {
			font: ${({ theme }) => theme.fonts.heading.card};
		}
		p {
			font: ${({ theme }) => theme.fonts.paragraph.xl};
			width: 100%;
		}
	}

	@media (min-width: 768px) {
		h6 {
			font: ${({ theme }) => theme.fonts.heading.medium};
		}
		p {
			font: ${({ theme }) => theme.fonts.paragraph.large};
			width: 100%;
		}
	}

	@media (min-width: 1220px) {
		gap: 36px;
		h6 {
			font: ${({ theme }) => theme.fonts.heading.small};
		}
	}

	@media (min-width: 1440px) {
		h6 {
			font: ${({ theme }) => theme.fonts.heading.mediumLarge};
		}
		p {
			font: ${({ theme }) => theme.fonts.paragraph.mediumPlus};
			width: 100%;
		}
	}

	@media (min-width: 1920px) {
		h6 {
			font: ${({ theme }) => theme.fonts.heading.card};
		}
		p {
			font: ${({ theme }) => theme.fonts.paragraph.xl};
			width: 100%;
		}
	}
`;
