import React from 'react';

import { ScExplanationCardWrapper } from './styled';

export const ExplanationCard = ({ title, text }: { title: string; text: string[] }) => {
	return (
		<ScExplanationCardWrapper>
			<h6 dangerouslySetInnerHTML={{ __html: title }} />
			<p dangerouslySetInnerHTML={{ __html: text }} />
		</ScExplanationCardWrapper>
	);
};
